.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.iframe{
  height: 100%;
  width: 100%;
}

.rsis-container > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.image-box > div {
  background-color: transparent !important;
}